module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"team-business","accessToken":"MC5YdXg2U2hJQUFDTUExWHJf.77-9M--_ve-_vREU77-977-977-9ODrvv73vv71-Dxnvv71z77-9HHTvv73vv73vv70Ea--_vQPvv70QfO-_vQ","omitPrismicScript":true,"defaultLang":"da-dk","langs":["da-dk"],"path":"/preview","previews":true,"shortenUrlLangs":true,"pages":[{"type":"Page","match":"/:lang?/:uid?","component":"/opt/build/repo/src/templates/page.jsx","langs":["da-dk"]},{"type":"Index","match":"/:lang?","component":"/opt/build/repo/src/templates/home.jsx","langs":["da-dk"]}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mostly Code","short_name":"mostlycode","start_url":"/","background_color":"#F2F2F2","theme_color":"#BCBF5E","display":"standalone","icon":"src/images/logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
